var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container finishedMaterials formPart" }, [
    !_vm.isEdit
      ? _c("div", [
          _c("div", { staticClass: "actions_part clearfix" }, [
            _c("div", { staticClass: "actions_wrap" }, [
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("物料编号：")]),
                  _c("el-input", {
                    staticClass: "input_single w200 mr10",
                    attrs: { placeholder: "请输入" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchHandle($event)
                      }
                    },
                    model: {
                      value: _vm.materialCode,
                      callback: function($$v) {
                        _vm.materialCode =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "materialCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("更新人：")]),
                  _c("el-input", {
                    staticClass: "input_single w200 mr10",
                    attrs: { placeholder: "请输入" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchHandle($event)
                      }
                    },
                    model: {
                      value: _vm.updateUser,
                      callback: function($$v) {
                        _vm.updateUser =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "updateUser"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("供应商：")]),
                  _c(
                    "el-select",
                    {
                      staticClass: "select_single w200 mr10",
                      attrs: {
                        filterable: "",
                        multiple: "",
                        "collapse-tags": "",
                        placeholder: "请选择"
                      },
                      on: { change: _vm.searchHandle },
                      model: {
                        value: _vm.supplierIdList,
                        callback: function($$v) {
                          _vm.supplierIdList = $$v
                        },
                        expression: "supplierIdList"
                      }
                    },
                    _vm._l(_vm.supplierNameList, function(item) {
                      return _c("el-option", {
                        key: item.supplierId,
                        attrs: {
                          label: item.supplierCode,
                          value: item.supplierId
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("更新日期：")]),
                  _c("el-date-picker", {
                    attrs: {
                      "unlink-panels": "",
                      type: "daterange",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    on: { change: _vm.dateValChange },
                    model: {
                      value: _vm.dateVal,
                      callback: function($$v) {
                        _vm.dateVal = $$v
                      },
                      expression: "dateVal"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "actions_btn_wrap down t_right" },
              [
                _c("el-button", {
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-search",
                    circle: "",
                    title: "搜索"
                  },
                  on: { click: _vm.searchHandle }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "table_part clearfix" },
            [
              _c(
                "el-tabs",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading"
                    }
                  ],
                  staticClass: "subnav_wrap",
                  staticStyle: { "min-height": "536px" }
                },
                [
                  _c("singleTable", {
                    attrs: {
                      tableList: _vm.materialsList,
                      tableLeaderFieldsList: _vm.leaderFieldsList,
                      tableTailFieldsList: _vm.tailFieldsList,
                      tableFieldsList: _vm.fieldsList
                    },
                    on: { checkHandle: _vm.editHandle }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pagination_wrap fr" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.currentPage,
                      "page-sizes": [10, 50],
                      "page-size": _vm.pageSize,
                      layout: "sizes, total, prev, pager, next",
                      total: this.total
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                      "update:currentPage": function($event) {
                        _vm.currentPage = $event
                      },
                      "update:current-page": function($event) {
                        _vm.currentPage = $event
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      : _c("div", { staticClass: "form_part clearfix" }, [
          _c(
            "div",
            { staticClass: "base_wrap clearfix" },
            [
              _c("h2", { staticClass: "title" }, [_vm._v("物料基本信息")]),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    inline: true,
                    "label-width": "200px"
                  }
                },
                [
                  _c("el-form-item", { attrs: { label: "物料编号" } }, [
                    _c("span", { staticClass: "w300" }, [
                      _vm._v(_vm._s(_vm.ruleForm.materialCode))
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "系统编号" } }, [
                    _c("span", { staticClass: "w300" }, [
                      _vm._v(_vm._s(_vm.ruleForm.materialId))
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "物料名称" } }, [
                    _c("span", { staticClass: "w300" }, [
                      _vm._v(_vm._s(_vm.ruleForm.materialName))
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "供应商编号" } }, [
                    _c("span", { staticClass: "w300" }, [
                      _vm._v(
                        _vm._s(
                          _vm.typeHandleSupplier(
                            _vm.ruleForm.supplierId,
                            this.supplierNameList
                          )
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "供应商名称" } }, [
                    _c("span", { staticClass: "w300" }, [
                      _vm._v(_vm._s(_vm.ruleForm.supplierName))
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "物料分类" } }, [
                    _c("span", { staticClass: "w300" }, [
                      _vm._v(
                        _vm._s(
                          _vm.typeHandle(
                            _vm.ruleForm.materialClassification,
                            this.materialClassList
                          )
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "颜色" } }, [
                    _c("span", { staticClass: "w300" }, [
                      _vm._v(
                        _vm._s(
                          _vm.typeHandle(_vm.ruleForm.color, this.colortypelist)
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "产品特性" } }, [
                    _c("span", { staticClass: "w300" }, [
                      _vm._v(
                        _vm._s(
                          _vm.typeHandle(
                            _vm.ruleForm.productSeason,
                            this.seasonList
                          )
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "采购计量单位" } }, [
                    _c("span", { staticClass: "w300" }, [
                      _vm._v(
                        _vm._s(
                          _vm.typeHandle(
                            _vm.ruleForm.measurementUnit,
                            this.unitList
                          )
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "单位重量" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.ruleForm.unitGram ? _vm.ruleForm.unitGram : ""
                        )
                      )
                    ]),
                    _vm._v("  克\n          ")
                  ]),
                  _c("el-form-item", { attrs: { label: "物料属性" } }, [
                    _c("span", { staticClass: "w300" }, [
                      _vm._v(
                        _vm._s(
                          _vm.typeHandle(
                            _vm.ruleForm.materialProperties,
                            this.MaterialPropertiestypelist
                          )
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "系列" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.ruleForm.materialSeries
                            ? _vm.ruleForm.materialSeries
                            : ""
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "上市时间" } }, [
                    _c("span", { staticClass: "w300" }, [
                      _vm._v(_vm._s(_vm.ruleForm.listingDate))
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "生命周期" } }, [
                    _c("span", { staticClass: "w300" }, [
                      _vm._v(_vm._s(_vm.ruleForm.lifeCycle))
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "报关品类" } }, [
                    _c("span", { staticClass: "w300" }, [
                      _vm._v(
                        _vm._s(
                          _vm.typeHandle(
                            _vm.ruleForm.declarationCategory,
                            this.materialDeclarationCategorylist
                          )
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "海关编号" } }, [
                    _c("span", { staticClass: "w300" }, [
                      _vm._v(_vm._s(_vm.ruleForm.hsCode))
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "杂费系数" } }, [
                    _c("span", { staticClass: "w300" }, [
                      _vm._v(_vm._s(_vm.ruleForm.miscChargesCoefficient) + "  ")
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "海关关税系数" } }, [
                    _c("span", { staticClass: "w300" }, [
                      _vm._v(_vm._s(_vm.ruleForm.customsTariffCoefficient))
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "增值税系数" } }, [
                    _c("span", { staticClass: "w300" }, [
                      _vm._v(_vm._s(_vm.ruleForm.valueAddedTaxCoefficient))
                    ])
                  ]),
                  _c(
                    "el-form-item",
                    { staticClass: "whole", attrs: { label: "备注" } },
                    [_c("span", [_vm._v(_vm._s(_vm.ruleForm.remarks))])]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mt20 t_right" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "mr10",
                      attrs: { type: "primary" },
                      on: { click: _vm.baseCancelHandle }
                    },
                    [_vm._v("取消")]
                  )
                ],
                1
              )
            ],
            1
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }