var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container compositeMaterials" }, [
    !_vm.isEdit
      ? _c("div", [
          _c("div", { staticClass: "actions_part clearfix" }, [
            _c("div", { staticClass: "actions_wrap" }, [
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [
                    _vm._v("组合物料编号：")
                  ]),
                  _c("el-input", {
                    staticClass: "input_single w200 mr10",
                    attrs: { placeholder: "请输入" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchHandle($event)
                      }
                    },
                    model: {
                      value: _vm.materialCode,
                      callback: function($$v) {
                        _vm.materialCode =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "materialCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("更新人：")]),
                  _c("el-input", {
                    staticClass: "input_single w200 mr10",
                    attrs: { placeholder: "请输入" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchHandle($event)
                      }
                    },
                    model: {
                      value: _vm.updateUser,
                      callback: function($$v) {
                        _vm.updateUser =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "updateUser"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [
                    _vm._v("组合物料分类：")
                  ]),
                  _c(
                    "el-select",
                    {
                      staticClass: "select_single w200 mr10",
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.searchHandle },
                      model: {
                        value: _vm.materialClassification,
                        callback: function($$v) {
                          _vm.materialClassification = $$v
                        },
                        expression: "materialClassification"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "",
                        attrs: { label: "全部", value: "" }
                      }),
                      _vm._l(this.MaterialClasstypelist, function(item) {
                        return _c("el-option", {
                          key: item.index,
                          attrs: { label: item.name, value: item.index }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("更新日期：")]),
                  _c("el-date-picker", {
                    attrs: {
                      "unlink-panels": "",
                      type: "daterange",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    on: { change: _vm.dateValChange },
                    model: {
                      value: _vm.dateVal,
                      callback: function($$v) {
                        _vm.dateVal = $$v
                      },
                      expression: "dateVal"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "actions_btn_wrap down t_right" },
              [
                _c("el-button", {
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-search",
                    circle: "",
                    title: "搜索"
                  },
                  on: { click: _vm.searchHandle }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "table_part clearfix" },
            [
              _c(
                "el-tabs",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading"
                    }
                  ],
                  staticClass: "subnav_wrap",
                  staticStyle: { "min-height": "536px" }
                },
                [
                  _c("singleTable", {
                    attrs: {
                      tableList: _vm.fabricList,
                      tableLeaderFieldsList: _vm.leaderFieldsList,
                      tableTailFieldsList: _vm.tailFieldsList,
                      tableFieldsList: _vm.fieldsList
                    },
                    on: { checkHandle: _vm.editHandle }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pagination_wrap fr" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.currentPage,
                      "page-sizes": [10, 50],
                      "page-size": _vm.pageSize,
                      layout: "sizes, total, prev, pager, next",
                      total: this.total
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                      "update:currentPage": function($event) {
                        _vm.currentPage = $event
                      },
                      "update:current-page": function($event) {
                        _vm.currentPage = $event
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      : _c("div", { staticClass: "form_part clearfix" }, [
          _c(
            "div",
            { staticClass: "base_wrap clearfix finished" },
            [
              _c("h2", { staticClass: "title" }, [_vm._v("物料基本信息")]),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    model: _vm.ruleForm,
                    "label-width": "200px"
                  }
                },
                [
                  _c("el-form-item", { attrs: { label: "物料编号" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.ruleForm.materialCode))])
                  ]),
                  _c("el-form-item", { attrs: { label: "系统编号" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.ruleForm.materialId))])
                  ]),
                  _c("el-form-item", { attrs: { label: "物料名称" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.ruleForm.materialName))])
                  ]),
                  _c("el-form-item", { attrs: { label: "生产商名称" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.ruleForm.materialCode))])
                  ]),
                  _c("el-form-item", { attrs: { label: "物料分类" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.typeHandle(
                            _vm.ruleForm.materialClassification,
                            this.MaterialClasstypelist
                          )
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "供应商编号" } }, [
                    _c("span", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.typeHandleSupplier(
                              _vm.ruleForm.supplierId,
                              this.supplierNameList
                            )
                          ) +
                          "\n           "
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "物料属性" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.typeHandle(
                            _vm.ruleForm.materialProperties,
                            this.MaterialPropertiestypelist
                          )
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "供应商名称" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.ruleForm.supplierName
                            ? _vm.ruleForm.supplierName
                            : ""
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "产品特性" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.typeHandle(
                            _vm.ruleForm.productSeason,
                            this.seasontypelist
                          )
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "总页数" } }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.ruleForm.pageCount) + "  页")
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "销售季" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.typeHandle(
                            _vm.ruleForm.saleSeason,
                            this.SaleSeasoutypelist
                          )
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "品牌" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.typeHandle(
                            _vm.ruleForm.brand,
                            this.MaterialCodetypelist
                          )
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "计量单位" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.typeHandle(
                            _vm.ruleForm.calculationUnit,
                            this.SaleUnittypelist
                          )
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "上市时间" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.ruleForm.validDate))])
                  ]),
                  _c("el-form-item", { attrs: { label: "单位重量" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.ruleForm.unitGram ? _vm.ruleForm.unitGram : "450"
                        )
                      )
                    ]),
                    _vm._v("  克\n          ")
                  ]),
                  _c("el-form-item", { attrs: { label: "报关品类" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.typeHandle(
                            _vm.ruleForm.declarationCategory,
                            this.materialDeclarationCategorylist
                          )
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "生命周期" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.ruleForm.invalidDate))])
                  ]),
                  _c("el-form-item", { attrs: { label: "海关编号" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.ruleForm.hsCode))])
                  ]),
                  _c("el-form-item", { attrs: { label: "杂费系数" } }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.ruleForm.miscChargesCoefficient) + "  ")
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "海关关税系数" } }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.ruleForm.customsTariffCoefficient))
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "增值税系数" } }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.ruleForm.valueAddedTaxCoefficient))
                    ])
                  ]),
                  _c(
                    "el-form-item",
                    { staticClass: "whole", attrs: { label: "备注" } },
                    [_c("span", [_vm._v(_vm._s(_vm.ruleForm.remarks))])]
                  ),
                  _c("hr", { staticStyle: { border: "0.5px solid #DCDFE6" } }),
                  _c("div", { staticClass: "childtit" }, [
                    _c("h2", { staticClass: "title" }, [
                      _vm._v("组合物料组成信息")
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "addcontent_content" },
                    [
                      _vm._l(
                        this.ruleForm.combinedMaterialMessagesList,
                        function(item, index) {
                          return _vm.ifchildshow == true
                            ? _c(
                                "ul",
                                { key: index, staticClass: "content_form" },
                                [
                                  _c("li", { staticClass: "w20p" }, [
                                    _c("div", { staticClass: "form_item" }, [
                                      _c("span", [_vm._v("组成物料编号:")]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(item.combinedMaterialCode)
                                        )
                                      ])
                                    ])
                                  ]),
                                  _c("li", { staticClass: "w8p" }, [
                                    _c("div", { staticClass: "form_item" }, [
                                      _c("span", [_vm._v("页码:")]),
                                      _c("span", [
                                        _vm._v(_vm._s(item.pageNumber))
                                      ])
                                    ])
                                  ]),
                                  _c("li", { staticClass: "w9p" }, [
                                    _c("div", { staticClass: "form_item" }, [
                                      _c("span", [_vm._v("位置:")]),
                                      _c("span", [
                                        _vm._v(_vm._s(item.position))
                                      ])
                                    ])
                                  ]),
                                  _c("li", { staticClass: "w7p" }, [
                                    _c("div", { staticClass: "form_item" }, [
                                      _c("span", [_vm._v("拍照款:")]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.photoStyle == true
                                              ? "是"
                                              : "否"
                                          )
                                        )
                                      ])
                                    ])
                                  ]),
                                  _c("li", { staticClass: "w12p" }, [
                                    _c("div", { staticClass: "form_item" }, [
                                      _c("span", [_vm._v("备注:")]),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            overflow: "hidden",
                                            "text-overflow": "ellipsis",
                                            "white-space": "nowrap"
                                          },
                                          attrs: { title: item.remarks }
                                        },
                                        [_vm._v(_vm._s(item.remarks))]
                                      )
                                    ])
                                  ]),
                                  _c("li", { staticClass: "w3p" })
                                ]
                              )
                            : _vm._e()
                        }
                      ),
                      _vm.ifchildshow == false
                        ? _c(
                            "div",
                            {
                              staticClass: "formtable_null",
                              staticStyle: {
                                "text-align": "center",
                                "margin-top": "15px"
                              }
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  width: "200px",
                                  height: "100px"
                                },
                                attrs: {
                                  src: require("../../../assets/image/add.png")
                                }
                              }),
                              _c("p", [_vm._v("信息空空如也")])
                            ]
                          )
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt20 fr" },
            [
              _c(
                "el-button",
                {
                  staticClass: "mr10",
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.resetForm("ruleForm")
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }