var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container fabricMaterials" },
    [
      !_vm.isEdit
        ? _c("div", [
            _c("div", { staticClass: "actions_part clearfix" }, [
              _c("div", { staticClass: "actions_wrap" }, [
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("物料编号：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.materialCode,
                        callback: function($$v) {
                          _vm.materialCode =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "materialCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("更新人：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.updateUser,
                        callback: function($$v) {
                          _vm.updateUser =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "updateUser"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("供应商：")]),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10",
                        attrs: {
                          filterable: "",
                          multiple: "",
                          "collapse-tags": "",
                          placeholder: "请选择"
                        },
                        on: { change: _vm.searchHandle },
                        model: {
                          value: _vm.supplierIdList,
                          callback: function($$v) {
                            _vm.supplierIdList = $$v
                          },
                          expression: "supplierIdList"
                        }
                      },
                      _vm._l(_vm.supplierNameList, function(item) {
                        return _c("el-option", {
                          key: item.supplierId,
                          attrs: {
                            label: item.supplierCode,
                            value: item.supplierId
                          }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("更新日期：")]),
                    _c("el-date-picker", {
                      attrs: {
                        "unlink-panels": "",
                        type: "daterange",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      on: { change: _vm.dateValChange },
                      model: {
                        value: _vm.dateVal,
                        callback: function($$v) {
                          _vm.dateVal = $$v
                        },
                        expression: "dateVal"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "actions_btn_wrap down t_right" },
                [
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-search",
                      circle: "",
                      title: "搜索"
                    },
                    on: { click: _vm.searchHandle }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "table_part clearfix" },
              [
                _c(
                  "el-tabs",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.tableLoading,
                        expression: "tableLoading"
                      }
                    ],
                    staticClass: "subnav_wrap",
                    staticStyle: { "min-height": "536px" }
                  },
                  [
                    _c("singleTable", {
                      attrs: {
                        tableList: _vm.fabricList,
                        tableLeaderFieldsList: _vm.leaderFieldsList,
                        tableTailFieldsList: _vm.tailFieldsList,
                        tableFieldsList: _vm.fieldsList
                      },
                      on: { checkHandle: _vm.editHandle }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "pagination_wrap fr" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.currentPage,
                        "page-sizes": [10, 50],
                        "page-size": _vm.pageSize,
                        layout: "sizes, total, prev, pager, next",
                        total: this.total
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange,
                        "update:currentPage": function($event) {
                          _vm.currentPage = $event
                        },
                        "update:current-page": function($event) {
                          _vm.currentPage = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _c("div", { staticClass: "form_part clearfix" }, [
            _c(
              "div",
              { staticClass: "base_wrap clearfix finished" },
              [
                _c("h2", { staticClass: "title" }, [_vm._v("物料基本信息")]),
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "demo-form-inline",
                    attrs: {
                      inline: true,
                      model: _vm.ruleForm,
                      "label-width": "200px"
                    }
                  },
                  [
                    _c("el-form-item", { attrs: { label: "物料编号" } }, [
                      _c("span", [_vm._v(_vm._s(_vm.ruleForm.materialCode))])
                    ]),
                    _c("el-form-item", { attrs: { label: "系统编号" } }, [
                      _c("span", [_vm._v(_vm._s(_vm.ruleForm.materialId))])
                    ]),
                    _c("el-form-item", { attrs: { label: "开发号" } }, [
                      _c(
                        "span",
                        {
                          staticClass: "overflow w60p inline_block",
                          attrs: { title: _vm.ruleForm.developCode }
                        },
                        [_vm._v(_vm._s(_vm.ruleForm.developCode))]
                      )
                    ]),
                    _c("el-form-item", { attrs: { label: "物料名称" } }, [
                      _c(
                        "span",
                        {
                          staticClass: "overflow w60p inline_block",
                          attrs: { title: _vm.ruleForm.materialName }
                        },
                        [_vm._v(_vm._s(_vm.ruleForm.materialName))]
                      )
                    ]),
                    _c("el-form-item", { attrs: { label: "供应商编号" } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.typeHandleSupplier(
                              _vm.ruleForm.supplierId,
                              this.supplierNameList
                            )
                          )
                        )
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "供应商名称" } }, [
                      _c(
                        "span",
                        {
                          staticClass: "overflow w60p inline_block",
                          attrs: { title: _vm.ruleForm.supplierName }
                        },
                        [_vm._v(_vm._s(_vm.ruleForm.supplierName))]
                      )
                    ]),
                    _c("el-form-item", { attrs: { label: "技术规格描述" } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.ruleForm.combination
                              ? _vm.ruleForm.combination
                              : ""
                          )
                        )
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "系列" } }, [
                      _c("span", [_vm._v(_vm._s(_vm.ruleForm.materialSeries))])
                    ]),
                    _c("el-form-item", { attrs: { label: "物料成分" } }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.ruleForm.materialComposition))
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "工厂季" } }, [
                      _c("span", [_vm._v(_vm._s(_vm.ruleForm.factorySeason))])
                    ]),
                    _c("el-form-item", { attrs: { label: "品类" } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.typeHandle(
                              _vm.ruleForm.category,
                              this.ProductCategorytypelist
                            )
                          )
                        )
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "产品特性" } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.typeHandle(
                              _vm.ruleForm.productSeason,
                              this.seasontypelist
                            )
                          )
                        )
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "颜色" } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.typeHandle(
                              _vm.ruleForm.color,
                              this.colortypelist
                            )
                          )
                        )
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "采购计量单位" } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.typeHandle(
                              _vm.ruleForm.purchaseMeasurementUnit,
                              this.SaleUnittypelist
                            )
                          )
                        )
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "花型" } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.typeHandle(
                              _vm.ruleForm.pattern,
                              this.patterntypelist
                            )
                          )
                        )
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "延米克重" } }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.ruleForm.weightPerMeterShow) + "  克")
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "产地" } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.typeHandle(
                              _vm.ruleForm.placeOfMaterialion,
                              this.placeProductiontypelist
                            )
                          )
                        )
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "幅宽" } }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.ruleForm.widthShow) + "  ")
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "物料类型" } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.typeHandle(
                              _vm.ruleForm.materialType,
                              this.Materialtypelist
                            )
                          )
                        )
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "纱支" } }, [
                      _c(
                        "span",
                        {
                          staticClass: "overflow w60p inline_block",
                          attrs: { title: _vm.ruleForm.yarnCount }
                        },
                        [_vm._v(_vm._s(_vm.ruleForm.yarnCount))]
                      )
                    ]),
                    _c("el-form-item", { attrs: { label: "物料属性" } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.typeHandle(
                              _vm.ruleForm.materialProperties,
                              this.MaterialPropertiestypelist
                            )
                          )
                        )
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "最小起订量" } }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.ruleForm.minOrder) + "  米")
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "新首最小起订量" } }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.ruleForm.newMinOrder) + "  米")
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "零剪物料属性" } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.typeHandle(
                              _vm.ruleForm.retailMaterialProperties,
                              this.materialPropertiesTypelist
                            )
                          )
                        )
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "经济批量" } }, [
                      _c(
                        "span",
                        {
                          staticClass: "overflow w60p inline_block",
                          attrs: { title: _vm.ruleForm.economicBatch }
                        },
                        [_vm._v(_vm._s(_vm.ruleForm.economicBatch))]
                      )
                    ]),
                    _c("el-form-item", { attrs: { label: "职业装物料属性" } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.typeHandle(
                              _vm.ruleForm.suitsMaterialProperties,
                              this.suitsMaterialPropertiesTypelist
                            )
                          )
                        )
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "规格" } }, [
                      _c("span", [_vm._v(_vm._s(_vm.ruleForm.specifications))])
                    ]),
                    _c("el-form-item", { attrs: { label: "上市日期" } }, [
                      _c("span", [_vm._v(_vm._s(_vm.ruleForm.appearDate))])
                    ]),
                    _c("el-form-item", { attrs: { label: "批次管理" } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.ruleForm.isBatchManagement == true ? "是" : "否"
                          )
                        )
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "生命周期" } }, [
                      _c("span", [_vm._v(_vm._s(_vm.ruleForm.disappearDate))])
                    ]),
                    _c("el-form-item", { attrs: { label: "EV产品" } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.ruleForm.belongToEv == true ? "是" : "否")
                        )
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "报关品类" } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.typeHandle(
                              _vm.ruleForm.declarationCategory,
                              this.materialDeclarationCategorylist
                            )
                          )
                        )
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "快货" } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.ruleForm.mobileGoods == true ? "是" : "否")
                        )
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "海关编号" } }, [
                      _c("span", [_vm._v(_vm._s(_vm.ruleForm.hsCode))])
                    ]),
                    _c("el-form-item", { attrs: { label: "是否可供" } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.typeHandle(
                              _vm.ruleForm.whetherSupply,
                              this.materialSupplyTypelist
                            )
                          )
                        )
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "海关关税系数" } }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.ruleForm.customsTariffCoefficient))
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "替代品" } }, [
                      _c("span", [_vm._v(_vm._s(_vm.ruleForm.substitute))])
                    ]),
                    _c("el-form-item", { attrs: { label: "增值税系数" } }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.ruleForm.valueAddedTaxCoefficient))
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "坯布编号" } }, [
                      _c("span", [_vm._v(_vm._s(_vm.ruleForm.rawFabricId))])
                    ]),
                    _c("el-form-item", { attrs: { label: "杂费系数" } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.ruleForm.miscChargesCoefficient) + "  "
                        )
                      ])
                    ]),
                    _c(
                      "el-form-item",
                      { staticClass: "whole", attrs: { label: "备注" } },
                      [_c("span", [_vm._v(_vm._s(_vm.ruleForm.remarks))])]
                    ),
                    _c("hr", {
                      staticStyle: { border: "0.5px solid #DCDFE6" }
                    }),
                    _c("h2", { staticClass: "title" }, [
                      _vm._v("交期信息（交期时间单位：天）")
                    ]),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "message",
                        attrs: { label: "工厂生产交期" }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.ruleForm.materialDeliveryDate
                                .factoryDeliveryDate
                            )
                          )
                        ])
                      ]
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "message",
                        attrs: { label: "发货（空运+清关+入库操作）交期" }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.ruleForm.materialDeliveryDate.shippmentDate
                            )
                          )
                        ])
                      ]
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "message",
                        attrs: { label: "预付款交期" }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.ruleForm.materialDeliveryDate
                                .advancePaymentDate
                            )
                          )
                        ])
                      ]
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "message",
                        attrs: { label: "新首工厂生产交期" }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.ruleForm.materialDeliveryDate
                                .firstFactoryDeliveryDate
                            )
                          )
                        ])
                      ]
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "message",
                        attrs: { label: "新首发货（空运+清关+入库操作）交期" }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.ruleForm.materialDeliveryDate
                                .firstShippmentDate
                            )
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mt20 fr" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "mr10",
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.resetForm("ruleForm")
                      }
                    }
                  },
                  [_vm._v("取消")]
                )
              ],
              1
            )
          ]),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            visible: _vm.dialogdelVisible,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogdelVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定删除该条面料物料主数据?")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogdelVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.delVisibleclick()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }